import React from "react"

const LazyVideo = ({ src, title, styles }) => {
  return (
    <div className="w-full h-auto">
      <iframe
        src={src}
        loading="lazy"
        title={title}
        allowFullScreen
        className={styles}
      />
    </div>
  )
}

export default LazyVideo
