import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Organisators = () => {
  const organisatorsData = [
    { name: "Wojciech Kornowski", org: "Zjednoczeni Ponad Podziałami" },
    {
      name: "Krzysztof Tytko",
      org: "Ogólnopolski Komitet Obrony Polskich Zasobów Naturalnych",
    },
  ]

  return (
    <>
      <div className="my-16 max-w-7xl mx-auto">
        <h3 className="text-3xl text-center font-bold tracking-tight text-gray-900 sm:text-4xl">
          Organizatorzy referendum
        </h3>
        <div className="flex flex-col items-center gap-4 md:grid md:grid-cols-2 mt-8">
          <div className="flex flex-col items-center overflow-hidden h-max">
            <div className="grid grid-cols-2 gap-2 h-32">
              <StaticImage
                src="../../images/organisators/wk.jpeg"
                alt={organisatorsData[0].name}
                className="h-32 w-auto ml-auto"
                imgClassName="h-32 w-auto ml-auto"
              />
              <StaticImage
                src="../../images/organisators/org_wk.jpg"
                alt={`Logo - ${organisatorsData[0].org}`}
                className="h-32 w-auto"
                imgClassName="h-32 w-auto"
              />
            </div>
            <div className="text-center mt-2">
              <span className="font-bold block text-lg">
                {organisatorsData[0].name}
              </span>
              <p>Przewodniczący - {organisatorsData[0].org}</p>
            </div>
          </div>

          <div className="flex flex-col items-center">
            <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 items-center gap-2 h-64 md:h-32">
              <StaticImage
                src="../../images/organisators/kt.jpeg"
                alt={organisatorsData[1].name}
                className="h-32 md:w-[150px] md:h-auto  w-auto mx-auto md:mx-none md:ml-auto"
                imgClassName="h-32 md:w-[150px]  md:h-auto w-auto mx-auto md:mx-none md:ml-auto"
              />
              <StaticImage
                src="../../images/organisators/org_kt.jpg"
                alt={`Logo - ${organisatorsData[1].org}`}
                className="h-32 w-auto md:w-[150px]  md:h-auto mx-auto md:mx-none md:ml-auto"
                imgClassName="h-32 w-auto md:w-[150px] md:h-auto mx-auto md:mx-none md:ml-auto"
              />
            </div>
            <div className="text-center mt-2">
              <span className="font-bold block text-lg">
                {organisatorsData[1].name}
              </span>
              <p>Przewodniczący - {organisatorsData[1].org}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Organisators
