import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Steps = () => {
  return (
    <div className="relative flex flex-col md:grid gap-8 row-gap-5 mb-8 md:row-gap-8 lg:grid-cols-4 sm:grid-cols-2 max-w-7xl mx-auto">
      <div className="absolute inset-0 flex items-center justify-center sm:hidden lg:flex">
        <div className="w-px h-full bg-gray-300 lg:w-full lg:h-px" />
      </div>
      <div className="p-5 duration-300 transform bg-white border-2 border-primary rounded shadow-sm hover:-translate-y-2">
        <div className="flex items-center justify-between mb-2">
          <p className="text-lg font-bold leading-5">Pobierz i wydrukuj</p>
          <p className="flex items-center justify-center w-max px-2 h-6 font-bold shadow-sm rounded-full text-bg bg-primary">
            Krok 1
          </p>
        </div>
        <div className="text-sm flex flex-col gap-2 h-full">
          <p className="text-gray-600">
            Pobierz i wydrukuj kartę referendalną do wypełnienia.
          </p>
          <p>
            Pomóż nam zbierać podpisy i wydrukuj dodatkowe karty dla przyjaciół.
          </p>
          <a
            href="../../downloads/karta.pdf"
            download="Karta referendum"
            target="_blank"
            rel="noreferrer noopener"
            className="inline-flex items-center text-bg bg-primary justify-center w-max h-8 px-6 font-medium tracking-wide  transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline border-primary border focus:outline-none hover:bg-bg hover:text-primary"
          >
            Pobierz teraz
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 ml-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
              />
            </svg>
          </a>
          <StaticImage
            src="../../images/karta.png"
            className="w-[200px] mt-auto md:mb-8 border"
            loading="eager"
            alt="karta referendum"
          />
        </div>
      </div>
      <div className="p-5 duration-300 transform bg-white border-2 border-primary rounded shadow-sm hover:-translate-y-2">
        <div className="flex items-center justify-between mb-2">
          <p className="text-lg font-bold leading-5">Wypełnij</p>
          <p className="flex items-center justify-center w-max px-2 h-6 font-bold shadow-sm rounded-full text-bg bg-primary">
            Krok 2
          </p>
        </div>
        <div className="flex text-sm flex-col gap-2 h-full">
          <p className="text-sm text-gray-900">
            Wypełnij czytelnie kartę, wypisz następująco:
          </p>
          <div className="text-sm">
            Imię (imiona), Nazwisko
            <br />
            Adres wraz z kodem pocztowym
            <br />
            PESEL
            <br />
            Własnoręczny podpis
          </div>
          <p>Poproś o wypełnienie karty swoją rodzinę, znajomych, sąsiadów.</p>
          <StaticImage
            src="../../images/clipboard.png"
            className="w-[100px] mt-auto md:mb-8"
            loading="eager"
            alt="ikona"
          />
        </div>
      </div>
      <div className="p-5 duration-300 transform bg-white border-2 border-primary rounded shadow-sm hover:-translate-y-2">
        <div className="flex items-center justify-between mb-2">
          <p className="text-lg font-bold leading-5">Wyślij pocztą</p>
          <p className="flex items-center justify-center w-max px-2 h-6 font-bold shadow-sm rounded-full text-bg bg-primary">
            Krok 3
          </p>
        </div>
        <div className="text-sm flex flex-col gap-2 h-full">
          <p className="text-sm text-gray-900">
            Wypełnioną kartę włóż w kopertę i wyślij na adres:
          </p>
          <div className="p-1 border w-max font-bold rounded">
            <span className="block">REFERENDUM - ZPP</span>
            <span className="block">Skryt. Poczt. nr 8</span>
            <span className="block">00-001 Warszawa 1</span>
          </div>
          <p>Opłata pocztowa za list zwykły wynosi 3,90 PLN.</p>
          <StaticImage
            src="../../images/mail-box.png"
            className="w-[100px] mt-auto md:mb-8"
            loading="eager"
            alt="ikona"
          />
        </div>
      </div>
      <div className="p-5 duration-300 transform bg-white border-2 border-primary rounded shadow-sm hover:-translate-y-2">
        <div className="flex items-center justify-between mb-2">
          <p className="text-lg font-bold leading-5">Lub dostarcz online</p>
          <p className="flex items-center justify-center w-max px-2 h-6 font-bold shadow-sm rounded-full text-bg bg-primary">
            Krok 4
          </p>
        </div>
        <div className="text-sm flex flex-col gap-2 h-full">
          <p className="text-sm text-gray-900">
            Możesz zrobić kolorowy skan (lub czytelne zdjęcie) wypełnionej karty
            i przesłać ją jako załącznik do formularza znajdującego się poniżej.
          </p>
          <p>
            Wszystkie karty są szyfrowane przed umieszczeniem ich na serwerze.
          </p>
          <Link
            to="/referendum#referendumForm"
            className="text-bg bg-primary inline-flex items-center justify-center align-start w-min h-8 px-4 py-2 font-medium tracking-wide  transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 border-primary border focus:shadow-outline focus:outline-none hover:bg-bg hover:text-primary"
          >
            Formularz
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 ml-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </Link>
          <StaticImage
            src="../../images/file_up.png"
            className="w-[100px] mt-auto md:mb-8"
            loading="eager"
            alt="ikona"
          />
        </div>
      </div>
    </div>
  )
}

export default Steps
