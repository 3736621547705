import React from "react"

const Organisations = () => {
  const orgList = [
    "Obywatelski Komitet Obrony Polskich Zasobów Naturalnych",
    "Zjednoczeni Ponad Podziałami",
    "Wolni i Solidarni",
    "Akcja Zjednoczonych Emerytów Rencistów",
    "Krajowy Związek Rolników, kółek i Organizacji Rolniczych",
    "Wspólnie dla Zdrowia",
    "Zjednoczeni dla Polski",
    "Janusz Dąbrowski Demokracja Bezpośrednia",
    "Stronnictwo „Piast”",
    "Stronnictwo Patriotyczne Polski i Polonii",
    "Stowarzyszenie Ogólnopolski Komitet Interwencji Społecznych",
    "Stowarzyszenie Siła Ponad Podziałami",
    "Przebudzeni Konsumenci",
    "Urząd Cywilnej i Demokratycznej Kontroli",
    "Stowarzyszenie Pokrzywdzonych przez System Bankowy i Prawny",
    "Fundacja Narodowy instytut Studiów Strategicznych",
    "Stronnictwo Narodowe im Dmowskiego Romana",
    "Prawo do życia",
    "Stowarzyszenie Małopolskie Bunt Droga Do Wolności",
    "Polonia Forum PL",
    "Front",
  ]

  return (
    <>
      <div className="text-center max-w-7xl mx-auto my-14 py-8">
        <span className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Zjednoczeni dla referendum
        </span>
        <div className="md:grid lg:grid-cols-2 mt-4">
          {orgList.map(e => {
            return (
              <span className="mt-4 text-md leading-6 text-gray-500 block">
                {e}
              </span>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Organisations
