// import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

//on-page content components
import DownloadFiles from "../@modules/referendum/downloadFiles"
import Form from "../@modules/referendum/form"
import Steps from "../@modules/referendum/steps"
import Organisators from "../@modules/referendum/organisators"
import Organisations from "../@modules/referendum/organisations"
import LazyVideo from "../components/video"

const Referendum = () => {
  return (
    <Layout>
      <Seo title="REFERENDUM" />
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
        <div className="mb-10 md:mx-auto sm:text-center md:mb-12">
          <div className="max-w-xl lg:max-w-2xl mx-auto">
            <StaticImage
              src="../images/logo_referendum.jpg"
              className="w-[200px] md:w-[360px] block mx-auto"
              alt="logo"
            />
            {/* <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider uppercase rounded-full bg-primary text-bg">
              REFERENDUM
            </p> */}
          </div>
          <LazyVideo
            src="https://www.youtube.com/embed/jMrpxN54LWM"
            title="Jak wygrać milion"
            styles="w-full md:max-w-3xl mx-auto my-16 aspect-video rounded-xl"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          />
          <h2 className="max-w-lg my-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative block">
              <span className="relative font-black text-primary">
                Walka o biliony złotych
              </span>
            </span>{" "}
            jest w Twoich rękach.
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            Mamy obowiązek uratowania naszych polskich zasobów naturalnych
            wartych biliony złotych...
            <a
              href="../images/list-referendum.png"
              target="_blank"
              rel="noreferrer noopener"
              className="text-link block"
            >
              Przeczytaj pełną treść listu{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 inline"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </a>
          </p>
          <p className="text-sm my-4 max-w-7xl mx-auto">
            Wygranie referendum pozwoli wypłacić każdemu Polakowi za jego życia
            około <b className="text-primary">milion złotych</b> z wydobycia
            bogactw naturalnych Polski. Wypłacane miesięcznie od{" "}
            <b className="text-primary">kilkuset do kilku tysięcy</b> złotych
            każdemu Polakowi pozwoli nasze społeczeństwo przenieść do klasy
            średniej, w której każdego będzie stać na{" "}
            <b className="text-primary">
              samochód, mieszkanie i wykształcenie dzieci.
            </b>
          </p>
          <span className="block">
            Wszystkie pliki są dostępne do pobrania po zapoznaniu się z krokami
            postępowania zlokalizowanymi poniżej.
          </span>
        </div>

        <Steps />
        <DownloadFiles />
        <Organisators />
        <Organisations />
        <Form />
      </div>
    </Layout>
  )
}

export default Referendum
